<template>
  <v-container>
    <v-dialog v-model="formMain.show" persistent max-width="600px">
      <v-form v-on:submit.prevent ref="form-main" v-model="formMain.valid">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="headline">{{formMain.isAddMode ? 'Add' : 'Edit'}} User</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field label="Name *" v-model="formMain.model.name" :rules="formMain.rules.name" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Username" v-model="formMain.model.username" :rules="formMain.rules.username" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Password" v-model="formMain.model.password" :rules="formMain.rules.password" :append-icon="formMain.showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="formMain.showPassword ? 'text' : 'password'" v-on:click:append="formMain.showPassword = !formMain.showPassword" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Email" v-model="formMain.model.email" :rules="formMain.rules.email" v-on:keydown.enter="saveForm"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete label="Role *" v-model="formMain.model.role_id" :rules="formMain.rules.role_id" :items="formItems.roles" item-text="name" item-value="id" v-on:keydown.enter="saveForm"></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Code" v-model="formMain.model.code" :rules="formMain.rules.code" :loading="formMain.loading.code">
                    <template slot="append">
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="formMain.showScanner = !formMain.showScanner" v-bind="attrs" v-on="on">mdi-barcode-scan</v-icon>
                        </template>
                        <span>Scan Code</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="hasCamera">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on:click="getRandomCode" v-bind="attrs" v-on="on">mdi-refresh</v-icon>
                        </template>
                        <span>Generate Code</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <StreamBarcodeReader v-if="formMain.showScanner" v-on:decode="onScannerDecode"></StreamBarcodeReader>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text v-on:click="formMain.show = false">
              Cancel
            </v-btn>
            <v-btn v-if="formMain.isAddMode" color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Add
            </v-btn>
            <v-btn v-else color="primary" :disabled="!formMain.valid" v-on:click="saveForm">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dgDelete.show" max-width="300">
      <v-card>
        <v-card-title class="headline">
          Delete?
        </v-card-title>
        <v-card-text>Are you sure to delete user: {{dgDelete.item ? dgDelete.item.name : '--'}}?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text v-on:click="dgDelete.show = false">
            Cancel
          </v-btn>
          <v-btn color="error" v-on:click="removeItem(dgDelete.item)">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="my-1">
      <v-col cols="12" sm="3">
        <v-btn elevation="4" color="success" block v-on:click="addItem"><v-icon left>mdi-plus</v-icon>Add</v-btn>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="items" item-key="name" :search="search" v-model="selected" :show-select="false">
      <template v-slot:top>
        <v-card color="primary white--text pa-2">
          <v-row align="center" no-gutters>
            <v-col cols="12" sm="8">
              <v-card-title>Users</v-card-title>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="search" clearable flat solo hide-details prepend-inner-icon="mdi-magnify" label="Search"></v-text-field>
            </v-col>
          </v-row>

        </v-card>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="editItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-pencil</v-icon></v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="item.id && item.id > 0" icon v-on:click="confirmRemove(item)" v-bind="attrs" v-on="on"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions-view="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on:click="viewItem(item)" v-bind="attrs" v-on="on"><v-icon>mdi-chevron-right</v-icon></v-btn>
          </template>
          <span>View Details</span>
        </v-tooltip>
      </template>
      <!-- <template v-slot:body.append>
        <tr>
          <td></td>
          <td>
            <v-text-field v-model="calories" type="number" label="less"></v-text-field>
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data: function() {
    return {
      search: "",
      headers: [
        { text: 'User',value: 'name' },
        { text: 'Username', value: 'username'},
        { text: 'Email', value: 'email'},
        { text: 'Role', value: 'role.name'},
        { text: 'Actions', value: 'actions', sortable: false, width: '120px' }
      ],
      items: [],
      selected: [],
      editingItem: null,
      dgDelete: {
        show: false,
        item: null,
      },
      formItems: {
        roles: [],
      },
      formMain: {
        show: false,
        isAddMode: true,
        valid: false,
        showPassword: false,
        showScanner: false,
        loading: {
          code: false
        },
        model: {
          id: null,
          name: null,
          username: null,
          password: null,
          email: null,
          code: null,
          role_id: null,
        },
        rules: {
          name: [
            v => !!v || 'Required'
          ],
          username: [],
          password: [],
          email: [
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Email must be valid'
          ],
          code: [
            v => !v || v.length == 16 || 'Code must be 16 digits'
          ],
          role_id: [
            v => !!v || 'Required',
          ],
        }
      },
    }
  },
  mounted() {
    this.getAllItems();
    this.getExtraItems();
  },
  methods: {
    getAllItems: function() {
      var _this = this;
      this.get('/user/getall', function(res){
        _this.items = res['data']['data']
      })
    },
    getExtraItems: function() {
      var _this = this;
      this.get('/role/getall', function(res){
        _this.formItems.roles = res['data']['data']
      })
    },
    getRandomCode: function() {
      var _this = this;
      this.formMain.loading.code = true;
      this.get('/user/getRandomCode', function(res){
        _this.formMain.model.code = res['data']['data']
        _this.formMain.loading.code = false;
      }, function(){
        _this.formMain.loading.code = false;
      }, {showSuccess: false, showError: true, showLoading: false})
    },
    addItem: function() {
      this.formMain.isAddMode = true;
      this.formMain.show = true;
      this.resetForm();
    },
    saveForm: function() {
      if (!this.formMain.valid) return;

      var url = '/user/add';
      if(!this.formMain.isAddMode)
        url = '/user/update/' + this.formMain.model.id;

      var _this = this;
      this.formMain.show = false;
      this.post(url, this.formMain.model, function(){
        _this.formMain.model.password = null;
        _this.formMain.model.code = null;
        _this.formMain.show = false;
        _this.getAllItems();
        _this.resetForm();
      }, function(){
        _this.formMain.show = true;
      }, {showSuccess: true, showError: true, showLoading: true});
    },
    resetForm: function() {
      this.formMain.model.id = null;
      this.formMain.model.name = null;
      this.formMain.model.username = null;
      this.formMain.model.password = null;
      this.formMain.model.email = null;
      this.formMain.model.code = null;
      this.formMain.model.role_id = null;
      this.$refs['form-main'].resetValidation();
    },
    editItem: function(item) {
      var _this = this;
      console.log(item)
      this.formMain.isAddMode = false;
      this.editingItem = JSON.parse(JSON.stringify(item));
      
      this.get('/user/get/' + item.id, function(res){
        _this.editingItem = res['data']['data']
        _this.formMain.model = _this.editingItem;
        _this.formMain.show = true;
      })
    },
    confirmRemove: function(item) {
      this.dgDelete.item = item;
      this.dgDelete.show = true;
    },
    removeItem: function(item) {
      console.log(item)
      var _this = this;
      this.dgDelete.show = false;
      
      this.post('/user/delete/' + item.id, null, function(){
        _this.getAllItems();
      })
    },
    onScannerDecode: function(result) {
      // var _this = this;
      this.formMain.model.code = result
      this.formMain.showScanner = false;
    }
  }
}
</script>